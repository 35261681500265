<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <!-- DETAILS -->
        <!-- Nom & Prénom  -->
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              label-cols-lg="2"
              label="NOM ET PRENOM"
              label-for="nom_prenom"
            >
              <validation-provider
                #default="{ errors }"
                name="Nom & Prenom"
                rules="required"
              >
                <b-form-input
                  v-model="sendForm.fullname"
                  placeholder="Nom et Prénom"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Age  -->
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              label-cols-lg="2"
              label="AGE"
              label-for="age"
            >
              <validation-provider
                #default="{ errors }"
                name="Age"
                rules="required|integer"
              >
                <b-form-input
                  v-model.number="sendForm.age"
                  placeholder="Age"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Sexe  -->
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              label-cols-lg="2"
              label="SEXE (GENDER)"
              label-for="sexe"
            >
              <validation-provider
                #default="{ errors }"
                name="Sexe"
                rules="required"
              >
                <b-form-radio-group
                  v-model="sendForm.sexe"
                  :options="sexe"
                  class="demo-inline-spacing"
                  name="radio-sexe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Motif principal  -->
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              label-cols-lg="2"
              label-size="lg"
              label="MOTIF PRINCIPAL DU VOYAGE"
              label-for="motif_travel"
            >
              <validation-provider
                #default="{ errors }"
                name="Motif Principal de Voyage"
                rules="required"
              >
                <b-form-radio-group
                  v-model="sendForm.motifVoyage"
                  :options="motif_travel"
                  value-field="id"
                  text-field="name"
                  name="some-radio9"
                  stacked
                />
                <!-- class="demo-inline-spacing control-label col-sm-3" -->
                <b-form-input
                  v-if="sendForm.motif_travel == 'Autres (A preciser)'"
                  v-model="sendForm.motifVoyage"
                  placeholder="Autres"
                  class="mt-2"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Mode d'hebergement  -->
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              label-cols-lg="2"
              label="MODE D'HEBERGEMENT"
              label-for="hebergment"
            >
              <validation-provider
                #default="{ errors }"
                name="Hebergement"
                rules="required"
              >
                <b-form-radio-group
                  v-model="sendForm.modeHebergement"
                  value-field="id"
                  text-field="name"
                  :options="hebergement"
                  class="demo-inline-spacing "
                  name="radio-validation"
                />
                <b-form-input
                  v-if="sendForm.modeHebergement == 'Autres (A preciser)'"
                  v-model="sendForm.modeHebergement"
                  placeholder="Autres"
                  class="mt-2"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Durée de sejour  -->
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              label-cols-lg="2"
              label="DUREE DU SÉJOUR"
              label-for="duree"
            >
              <validation-provider
                #default="{ errors }"
                name="Durée"
                rules="required"
              >
                <b-form-input
                  v-model.number="sendForm.dureeSejour"
                  placeholder="Durée"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Nom & Prénom enqueteur  -->
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Nom_Enqueteur"
              rules="required"
            >

              <b-form-group
                label-cols-lg="2"
                label="NOM DE L'ENQUÊTEUR"
                label-for="enqueteur"
              >
                <b-form-input
                  v-model.number="sendForm.enqueteur"
                  placeholder="Nom de l'enquêteur"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Rémuneration -->
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              label-cols-lg="2"
              label="ALLEZ-VOUS PERCEVOIR UNE RÉMUNÉRATION D'UNE ENTITE DU PAYS / LIEU VISITE DURANT VOTRE SÉJOUR ?"
              label-for="remuneration"
            >
              <validation-provider
                #default="{ errors }"
                name="Remuneration"
                rules="required"
              >
                <b-form-radio-group
                  v-model="sendForm.possibiliteRemuneration"
                  :options="remuneration"
                  class="demo-inline-spacing"
                  name="radio-inline"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  // BListGroup,
  // BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// import axios from 'axios'
// import { mapActions, mapState } from 'vuex'
// import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    // BListGroup,
    // BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
    // flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  // computed: {
  //   ...mapState('params', {
  //     listPays: 'listPays',
  //     listMois: 'listMois',
  //     listAnnees: 'listAnnees',
  //   }),
  // },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  }, //
  data() {
    return {
      email,
      required,
      // email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      // Select Motif_Travel: '',
      motif_travel: [
        { id: 'Affaires et motif professionnels', name: 'Affaires et motif professionnels' },
        { id: 'Vacances, loisirs et détente', name: 'Vacances, loisirs et détente' },
        { id: 'Visite à des parents ou des amis', name: 'Visite à des parents ou des amis' },
        { id: 'Éducation et formation', name: 'Éducation et formation' },
        { id: 'Santé et soins médicaux', name: 'Santé et soins médicaux' },
        { id: 'Transit', name: 'Transit' },
        { id: 'Religion ou pèlerinage', name: 'Religion ou pèlerinage' },
        { id: 'Achats', name: 'Achats' },
        { id: 'Autres (A preciser)', name: 'Autres motif (A preciser)' },
      ],
      // Radio Hebergement: '',
      hebergement: [
        { id: 'Hotel et assimilé', name: 'Hotel et assimilé' },
        { id: 'Familles/Amis', name: 'Familles/Amis' },
        // { id: 'Visite à des parents ou des amis', name: 'Visite à des parents ou des amis' },
        { id: 'Autres (A preciser)', name: 'Autres (A preciser)' },
      ],
      // Autre motif de voyage
      // autres_motif: [],
      // Autre hebergemnt
      // autres_hebergement: [],
      // Radio Remuneration: '',
      remuneration: [
        { text: 'Oui', value: true },
        { text: 'Non', value: false },
      ],
      // Select commune
      // commune: [
      //   { title: 'Cotonou' },
      //   { title: 'Bohicon' },
      //   { title: 'Parakou' },
      //   { title: 'Porto-novo' },
      // ],
      sexe: [
        { text: 'Masculin', value: 'Masculin' },
        { text: 'Feminin', value: 'Feminin' },
      ],

      sendForm: {
        dureeSejour: '',
        motifVoyage: '',
        modeHebergement: '',
        possibiliteRemuneration: '',
        // villeCommune: '',
        age: '',
        sexe: '',
        fullname: '',
        enqueteur: '',
        // autres_motif: '',
        // autres_hebergement: '',
      },
    }
  },

  // watch: {
  //   $route: {
  //     immediate: true,
  //     async handler(val, old) {
  //       // await this.action_fetchTypesRoles()
  //       this.getListEtablissement()
  //     },
  //   },
  // },
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees
    

    // this.loadQuartier();
    if (this.inputData) {
      this.sendForm = this.inputData
    } else if (this.$route.params.data) {
      this.sendForm = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },

  methods: {
    // ...mapActions('params', {
    //   fetchAnnees: 'fetchAnnees',
    // }),
    onCancel() {
      this.$emit('onCancel', {})
    },

    // ...mapActions('etablissements', { action_findEtablissement: 'findEtablissement' }),
    // getListEtablissement() {
    //   this.action_findEtablissement()
    //     .then(response => {
    //       this.lites_hotels = response.data.resources
    //       console.log('list-etablissement', response, this.lites_hotels)
    //     })
    //     .catch(err => {
    //       this.isLoadingTableData = false
    //       console.log('error-list-etablissement', err)
    //     })
    // },

    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          // if (this.infos) {
          //   if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
          //   if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
          //   if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
          //   if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
          //   if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
          //   if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

          //   if (this.infos.telephone2 == '') delete this.infos.telephone2
          //   if (this.infos.telephone3 == '') delete this.infos.telephone3
          //   if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement
          // }

          if (this.inputData) {
            this.$emit('onFormSuccess', this.sendForm)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.sendForm },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
