<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <!-- RENSEIGNEMENTS GENERAUX  -->
        <div>
          <b-card id="renseignementsgeneraux">
            <b-card-header class="d-flex justify-content-between">
              <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
            </b-card-header>
            <hr>
            <b-card-body>
              <!-- Date d'arrivée -->
              <b-row>
                <b-col
                  md="12"
                  class="mt-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Date"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="2"
                      label="DATE D'ARRIVEE AU BENIN"
                      label-for="date"
                    >

                      <flat-pickr
                        v-model="sendForm.dateArrivee"
                        class="form-control"
                        placeholder="Date"
                        value="date"
                        type="date"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Compagnie Aérienne -->
              <b-row>
                <b-col
                  md="12"
                  class="mt-2"
                >
                  <!-- Durée de sejour  -->
                  <b-form-group
                    label-cols-lg="2"
                    label="COMPAGNIE AÉRIENNE"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Compagnie Aérienne"
                      rules="required"
                    >
                      <b-form-input
                        v-model.number="sendForm.compagnieAerienne"
                        placeholder="Compagnie Aérienne"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Pays de résidence  -->
              <b-row>
                <b-col
                  md="12"
                  class="mt-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Pays de Résidence"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="2"
                      label="PAYS DE RESIDENCE"
                      label-for="pays_residence"
                    >

                      <v-select
                        v-model="sendForm.paysResidence"
                        type="text"
                        :options="pays_residence"
                        label="title"
                        placeholder="Pays de résidence"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Nationalité -->
              <b-row>
                <b-col
                  md="12"
                  class="mt-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nationalité"
                    rules="required"
                  >

                    <b-form-group
                      label-cols-lg="2"
                      label="NATIONALITE"
                      label-for="nationality"
                    >
                      <v-select
                        v-model="sendForm.nationalite"
                        type="text"
                        :options="nationality"
                        label="title"
                        placeholder="Nationalité"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Pays de destination -->
              <b-row>
                <b-col
                  md="12"
                  class="mt-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Pays de destination"
                    rules="required"
                  >

                    <b-form-group
                      label-cols-lg="2"
                      label="PAYS DE DESTINATION"
                      label-for="pays_destination"
                    >
                      <v-select
                        v-model="sendForm.paysDestination"
                        type="text"
                        :options="pays_destination"
                        label="title"
                        placeholder="Pays de destination"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  // BFormRadioGroup,
  // BListGroup,
  // BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    // BFormRadioGroup,
    BCard,
    // BListGroup,
    // BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  // computed: {
  //   ...mapState('params', {
  //     listPays: 'listPays',
  //     listMois: 'listMois',
  //     listAnnees: 'listAnnees',
  //   }),
  // },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  }, //
  data() {
    return {
      email,
      required,
      // email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      // Pays de residence
      pays_residence: ['Benin', 'Togo', 'Nigeria'],
      // Nationality
      nationality: [
        { title: 'Benin' },
        { title: 'Togolaise' },
        { title: 'Nigeria' },
      ],
      pays_destination: [
        { title: 'Ghana' },
        { title: 'Togo' },
        { title: 'Nigeria' },
      ],

      sendForm: {
        dateArrivee: '',
        compagnieAerienne: '',
        paysResidence: '',
        nationalite: '',
        paysDestination: '',
      },
    }
  },

  // watch: {
  //   $route: {
  //     immediate: true,
  //     async handler(val, old) {
  //       // await this.action_fetchTypesRoles()
  //       this.getListEtablissement()
  //     },
  //   },
  // },
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees
    

    // this.loadQuartier();
    if (this.inputData) {
      this.sendForm = this.inputData
    } else if (this.$route.params.data) {
      this.sendForm = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },

  methods: {
    // ...mapActions('params', {
    //   fetchAnnees: 'fetchAnnees',
    // }),
    onCancel() {
      this.$emit('onCancel', {})
    },

    // ...mapActions('etablissements', { action_findEtablissement: 'findEtablissement' }),
    // getListEtablissement() {
    //   this.action_findEtablissement()
    //     .then(response => {
    //       this.lites_hotels = response.data.resources
    //       console.log('list-etablissement', response, this.lites_hotels)
    //     })
    //     .catch(err => {
    //       // this.isLoadingTableData = false
    //       console.log('error-list-etablissement', err)
    //     })
    // },

    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          // if (this.infos) {
          //   if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
          //   if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
          //   if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
          //   if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
          //   if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
          //   if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

          //   if (this.infos.telephone2 == '') delete this.infos.telephone2
          //   if (this.infos.telephone3 == '') delete this.infos.telephone3
          //   if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement
          // }

          if (this.inputData) {
            this.$emit('onFormSuccess', this.sendForm)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.sendForm },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
